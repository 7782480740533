<template>
	<a-space direction="vertical">
		<a-card :title="cardTitle">
			<a-form-model ref="form" :model="formdata" :rules="rules" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
				<a-form-model-item label="标题" prop="title"><a-input placeholder="请输入标题" v-model="formdata.title" /></a-form-model-item>
				<a-form-model-item label="栏目" prop="category_ids">
					<a-cascader :options="categories" placeholder="请选择栏目" v-model="formdata.category_ids" />
				</a-form-model-item>
				<a-form-model-item label="封面" prop="banner"><ws-upload :url="formdata.banner" @success="e => (formdata.banner = e.url)" /></a-form-model-item>
				<a-form-model-item label="音频"><ws-upload :url="formdata.audio" type="audio" @success="e => (formdata.audio = e.url)" /></a-form-model-item>
				<a-form-model-item label="音频标题" prop="audioTitle"><a-input placeholder="请输入音频标题" v-model="formdata.audioTitle" /></a-form-model-item>
				<a-form-model-item label="音频简介" prop="audioIntroduce"><a-input placeholder="请输入简介" v-model="formdata.audioIntroduce" /></a-form-model-item>
				<a-form-model-item label="音频封面" prop="audioBanner"><ws-upload :url="formdata.audioBanner" @success="e => (formdata.audioBanner = e.url)" /></a-form-model-item>
				<a-form-model-item label="排序" prop="sort"><a-input placeholder="请输入排序号" v-model="formdata.sort" /></a-form-model-item>
				<a-form-model-item label="内容" prop="content"><div id="editor"></div></a-form-model-item>
				<a-form-model-item label="状态" prop="status">
					<a-radio-group v-model="formdata.status">
						<a-radio :value="1">上架</a-radio>
						<a-radio :value="2">下架</a-radio>
						<a-radio :value="3">草稿箱</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :wrapper-col="{ span: 18, offset: 4 }"><a-button type="primary" @click="onSubmit">保存</a-button></a-form-model-item>
			</a-form-model>
		</a-card>
	</a-space>
</template>

<script>
import moment from 'moment';
import { copy, loadjs, loadcss } from '@/utils';

import wsUpload from '@/components/WsUpload.vue';
const formatCategories = categories => {
	return categories.map(item => {
		if (Array.isArray(item.children)) {
			if (item.children.length === 0) {
				delete item.children;
			} else {
				item.children = formatCategories(item.children);
			}
		}
		return item;
	});
};
var editor = null;
export default {
	components: {
		wsUpload
	},
	data() {
		return {
			cardTitle: '新增文章',
			formdata: {
				title: '',
				content: '',
				banner: '',
				audio: '',
				category_ids: [],
				status: 3,
				sort: 1,
				audioTitle: '',
				audioIntroduce: '',
				audioBanner: ''
			},
			rules: {
				title: [
					{
						required: true,
						message: '请输入标题',
						trigger: 'blur'
					}
				],
				banner: [
					{
						required: true,
						message: '请上传图片',
						trigger: 'blur'
					}
				],
				category_ids: [
					{
						required: true,
						message: '请选择栏目',
						trigger: 'blur'
					}
				],
				audioTitle: [
					{
						validator: (rule, value, callback) => {
							if (this.formdata.audio && !value) {
								callback(new Error('请输入音频标题'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}
				],
				audioIntroduce: [
					{
						validator: (rule, value, callback) => {
							if (this.formdata.audio && !value) {
								callback(new Error('请输入音频简介'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}
				],
				sort: [
					{
						required: true,
						message: '请输入排序号',
						trigger: 'blur'
					}
				],
				content: [
					{
						required: true,
						message: '请输入内容',
						trigger: 'blur'
					}
				]
			},
			categories: []
		};
	},
	computed: {},
	async mounted() {
		console.info('mounted');
		window.UEDITOR_HOME_URL = '/ueditor/';
		loadcss('/ueditor/xiumi-ue-v5.css');
		await loadjs('/ueditor/ueditor.config.js');
		await loadjs('/ueditor/ueditor.all.js');
		await loadjs('/ueditor/xiumi-ue-dialog-v5.js');
		if (editor) {
			editor.destroy();
		}
		// UE.Editor().getActionUrl =
		editor = UE.getEditor('editor', {
			initialFrameWidth: null,
			initialFrameHeight: 500,
			toolbars: [
				[
					'fullscreen',
					'source',
					'|',
					'undo',
					'redo',
					'|',
					'bold',
					'italic',
					'underline',
					'fontborder',
					'strikethrough',
					'superscript',
					'subscript',
					'removeformat',
					'formatmatch',
					'autotypeset',
					'blockquote',
					'pasteplain',
					'|',
					'forecolor',
					'backcolor',
					'insertorderedlist',
					'insertunorderedlist',
					'selectall',
					'cleardoc',
					'|',
					'rowspacingtop',
					'rowspacingbottom',
					'lineheight',
					'|',
					'customstyle',
					'paragraph',
					'fontfamily',
					'fontsize',
					'|',
					'directionalityltr',
					'directionalityrtl',
					'indent',
					'|',
					'justifyleft',
					'justifycenter',
					'justifyright',
					'justifyjustify',
					'|',
					'touppercase',
					'tolowercase',
					'|',
					'link',
					'unlink',
					'anchor',
					'|',
					'imagenone',
					'imageleft',
					'imageright',
					'imagecenter',
					'|',
					// 'simpleupload',
					'insertimage',
					'emotion',
					'scrawl',
					'insertvideo',
					'music',
					'attachment',
					'map',
					// 'gmap',
					'insertframe',
					'insertcode',
					'webapp',
					'pagebreak',
					'template',
					'background',
					'|',
					'horizontal',
					'date',
					'time',
					'spechars',
					'snapscreen',
					'wordimage',
					'|',
					'inserttable',
					'deletetable',
					'insertparagraphbeforetable',
					'insertrow',
					'deleterow',
					'insertcol',
					'deletecol',
					'mergecells',
					'mergeright',
					'mergedown',
					'splittocells',
					'splittorows',
					'splittocols',
					'charts',
					'|',
					'print',
					'preview',
					'searchreplace',
					'drafts',
					'help'
				]
			]
		});
		editor.ready(async () => {
			editor.addListener('contentChange', e => {
				this.formdata.content = editor.getContent();
			});
			this.loadCategories();
			if (this.$route.name == 'articles-edit') {
				this.cardTitle = '编辑文章';
				const { id } = this.$route.params;
				const response = await this.$api.get(`/wechat_article_detail/${id}`);
				if (response && response.code == 200) {
					const { firstMenu, twoMenu, threeMenu, ...formdata } = response.data;
					Object.assign(this.formdata, formdata, { category_ids: [firstMenu, twoMenu, threeMenu].filter(v => !!v) });
					editor.setContent(formdata.content);
				}
			}
		});
	},
	methods: {
		async loadCategories() {
			const response = await this.$api.get('/wechat_menu_list');
			if (response && response.code == 200) {
				this.categories = formatCategories(response.data || []);
			}
		},
		onSubmit() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					this.$store.dispatch('setLoading', 300000);
					const { category_ids, ...data } = copy(this.formdata);
					const [firstMenu, twoMenu, threeMenu] = category_ids;
					Object.assign(data, { firstMenu, twoMenu, threeMenu });
					const response = await this.$api.post('/wechat_article', data,{
						timeout: 300000
					});
					if (response && response.code == 200) {
						this.$message.success('保存成功!');
						this.$router.replace({ name: 'articles' });
					} else {
						this.$message.error(response.msg || '保存失败!');
					}
					setTimeout(()=>{
						this.$store.dispatch('setLoading', false);
					}, 500)
					
				}
			});
		}
	}
};
</script>

<style lang="less">
#editor {
	// width: 100%;
	max-width: 100%;
	line-height: 1.44;

	*,
	*::before,
	*::after {
		box-sizing: content-box;
	}
}
</style>
